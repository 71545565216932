import React from "react"
import Header from "./header"
import Footer from "./footer"

export default function Layout({children}) {
   return (
        <div id="wrapper">
            <Header/>
            {children}
            <Footer/>
        </div>
    )
}
