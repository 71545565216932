import React from 'react';
import PropTypes from 'prop-types';
import {
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton,
    WhatsappShareButton,
} from 'react-share';

const Share = ({ socialConfig, tags }) => (

        <div className="si-share border-0 d-flex justify-content-end align-items-end">
            <span>Paylaş:</span>
            <div>
                <FacebookShareButton url={socialConfig.config.url} className="social-icon si-borderless si-facebook" >
                    <i className="icon-facebook"></i>
                    <i className="icon-facebook"></i>
                </FacebookShareButton>

                <TwitterShareButton url={socialConfig.config.url} className="social-icon si-borderless si-twitter" title={socialConfig.config.title} via={'@varliktanveri'.split('@').join('')} hashtags={tags} >
                    <i className="icon-twitter"></i>
                    <i className="icon-twitter"></i>
                </TwitterShareButton>

                <EmailShareButton url={socialConfig.config.url} className="social-icon si-borderless si-email3"  title={socialConfig.config.title} >
                    <i className="icon-email3"></i>
                    <i className="icon-email3"></i>
                </EmailShareButton>
                <WhatsappShareButton url={socialConfig.config.url} className="social-icon si-borderless si-whatsapp" title={socialConfig.config.title} >
                    <i className="icon-whatsapp"></i>
                    <i className="icon-whatsapp"></i>
                </WhatsappShareButton>
            </div>
        </div>
    )


Share.propTypes = {
    socialConfig: PropTypes.shape({
        twitterHandle: PropTypes.string,
        config: PropTypes.shape({
            url: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }),
    }).isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
};
Share.defaultProps = {
    tags: [],
};

export default Share;