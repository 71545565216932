import React from "react"
import {graphql, Link} from 'gatsby';
import Layout from '../components/layout';
import Share from '../components/share';
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../hooks/use-site-metadata';


const SohbetPage = ({ data, location }) => {
    const { sohbet, previous, next } = data

    const { siteUrl, defaultImage } = useSiteMetadata();
    const ogImageUrl = `${siteUrl}${(sohbet.frontmatter.featuredImage && sohbet.frontmatter.featuredImage?.childImageSharp.fluid.src) || defaultImage}`;

    return (
        <Layout>
            <GatsbySeo
                title={sohbet.frontmatter.title}
                description={sohbet.excerpt}
                openGraph={{
                    url: `${siteUrl}${location.pathname}`,
                    title: `${sohbet.frontmatter.title}`,
                    images: [
                        {
                            url: ogImageUrl,
                            width: 1200,
                            height: 630
                        }
                    ]
                }}
            />

            <section id="page-title" className="page-title-center">

                <div className="container">
                    <h1>Tasavvuf Sohbetleri</h1>
                    <span>Kazım Yardımcı'nın sesinden şiirler ve gazeller</span>
                    {/*<ol className="breadcrumb">*/}
                    {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
                    {/*    <li className="breadcrumb-item active" aria-current="page">Blog</li>*/}
                    {/*</ol>*/}
                </div>

            </section>
            <section id="content">
                <div className="content-wrap pt-5">
                    <div className="container clearfix">

                        <div className="single-post mb-0">

                            <div className="entry clearfix">

                                <div className="entry-title">
                                    <h2>{sohbet.frontmatter.title}</h2>
                                </div>

                                <div className="entry-meta">
                                    <ul>
                                        <li><Link to="/kazim-yardimci"><i className="icon-user"/> Kazım Yardımcı</Link></li>
                                    </ul>
                                </div>

                                <div className="entry-image">
                                </div>

                                <div className="entry-content mt-0 col-lg-8">
                                    <section
                                        dangerouslySetInnerHTML={{ __html: sohbet.html }}
                                    />
                                    <div className="clear"></div>

                                    <Share socialConfig={{
                                        config: {
                                            url : location.href,
                                            title: sohbet.frontmatter.title
                                        }
                                    }} />
                                </div>
                            </div>

                            <div className="row justify-content-between col-mb-30 post-navigation">
                                <div className="col-12 col-md-auto text-center">
                                    {previous && (
                                        <Link to={`/${previous.frontmatter.slug}`} rel="prev">
                                            ← {previous.frontmatter.title}
                                        </Link>
                                    )}
                                </div>

                                <div className="col-12 col-md-auto text-center">
                                    {next && (
                                        <Link to={`/${next.frontmatter.slug}`} rel="next">
                                            {next.frontmatter.title} →
                                        </Link>
                                    )}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default SohbetPage

export const pageQuery = graphql`
  query (
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    sohbet: markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    
    previous: markdownRemark(id: { eq: $previousPostId }) {
      frontmatter {
        title
        slug
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      frontmatter {
        title
        slug
      }
    }
  }
`
