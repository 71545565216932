import React, {useEffect, useState} from "react"
import imgLogo from "../images/logo.png"
import imgLogoDark from "../images/logo-dark.png"
import imgLogoRetina from "../images/logo@2x.png"
import imgLogoDarkRetina from "../images/logo-dark@2x.png"
import imgLogoMobile from "../images/mobile-logo.png"
import imgLogoDarkMobile from "../images/mobile-logo-dark.png"
import imgLogoMobileRetina from "../images/mobile-logo@2x.png"
import imgLogoDarkMobileRetina from "../images/mobile-logo-dark@2x.png"
import imgFlagEn from "../images/flags/eng.png"
import imgFlagTr from "../images/flags/tr.png"
import {Link} from 'gatsby';

const Header = () => {

    const isMobileSize = useWindowSize();
    const [isDark, setIsDark] = useState(false)

    return (
        <header id="header" className="header-size-custom" data-sticky-shrink="false">
            <div id="header-wrap">
                <div className="container">
                    <div className="header-row justify-content-lg-between">

                        <div id="logo" className="mx-lg-auto col-auto flex-column order-lg-2 px-0">
                            <Link className="standard-logo" to={"/"}><img
                                src={isMobileSize ?
                                    (isDark ? imgLogoDarkMobile : imgLogoMobile) :
                                    (isDark ? imgLogoDark : imgLogo)} alt="{siteTitle}" />
                            </Link>
                            <Link className="retina-logo" to={"/"}><img
                                src={isMobileSize ?
                                    (isDark ? imgLogoDarkMobileRetina : imgLogoMobileRetina) :
                                    (isDark ? imgLogoDarkRetina : imgLogoRetina)} alt="{siteTitle}" /></Link>
                            <span className="divider divider-center date-today"><span
                                className="divider-text">Dinin Özü Tasavvuf</span></span>
                        </div>

                        <div className="col-auto col-lg-3 order-lg-1 d-none d-md-flex px-0">
                            <div className="social-icons">
                                <a href="https://www.facebook.com/varliktanveriler"
                                   aria-label="Facebook"
                                   className="social-icon si-rounded si-dark si-mini si-facebook" target="_blank" rel="noreferrer">
                                    <i className="icon-facebook"/>
                                    <i className="icon-facebook"/>
                                </a>
                                <a href="https://twitter.com/varliktanveri"
                                   aria-label="Twitter"
                                   className="social-icon si-rounded si-dark si-mini si-twitter" target="_blank" rel="noreferrer">
                                    <i className="icon-twitter"/>
                                    <i className="icon-twitter"/>
                                </a>
                                <a href="https://www.instagram.com/varliktanveriler"
                                   aria-label="Instagram"
                                   className="social-icon si-rounded si-dark si-mini si-instagram" target="_blank" rel="noreferrer">
                                    <i className="icon-instagram"/>
                                    <i className="icon-instagram"/>
                                </a>
                                <a href="https://www.youtube.com/user/varliktanveriler"
                                   aria-label="Youtube"
                                   className="social-icon si-rounded si-dark si-mini si-youtube" target="_blank" rel="noreferrer">
                                    <i className="icon-youtube"/>
                                    <i className="icon-youtube"/>
                                </a>
                            </div>
                        </div>

                        <div className="header-misc col-auto col-lg-3 justify-content-lg-end ml-0 ml-sm-3 px-0">

                            <div className="dropdown dropdown-langs">
                                <button aria-label="Dil Değiştir" className="btn dropdown-toggle px-1" type="button" id="dropdownMenuButton"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="icon-globe"/>
                                </button>

                                <div className="dropdown-menu dropdown-menu-right"
                                     aria-labelledby="dropdownMenuButton">

                                    <Link to="/en" className="dropdown-item" >
                                        <img src={imgFlagEn}
                                             alt="English"/> English
                                    </Link>
                                    <Link to="/" className="dropdown-item disabled" >
                                        <img src={imgFlagTr}
                                             alt="Türkçe"/> Türkçe
                                    </Link>
                                </div>
                            </div>

                            {/*<div id="top-search" className="header-misc-icon">*/}
                            {/*    <Link to="#" onClick={() => window.$('.top-search-form').find('input').focus()} id="top-search-trigger"><i className="icon-line-search"/>*/}
                            {/*        <i className="icon-line-cross"/>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}
                            <div className="dark-mode header-misc-icon d-none d-md-block">
                                <Link to="#" aria-label="Dark Mode"  onClick={() => {
                                    window.document.body.classList.toggle('dark');
                                    setIsDark(!isDark)
                                }}><i className="icon-dark"/></Link>
                            </div>
                        </div>

                        <div id="primary-menu-trigger">
                            <svg className="svg-trigger" viewBox="0 0 100 100">
                                <path
                                    d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"/>
                                <path d="m 30,50 h 40"/>
                                <path
                                    d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"/>
                            </svg>
                        </div>

                    </div>
                </div>

                <div className="container">
                    <div className="header-row justify-content-lg-center header-border">

                        <nav className="primary-menu with-arrows">

                            <ul className="menu-container justify-content-between">
                                <li className="menu-item"><Link to={'/kazim-yardimci'} className="menu-link" activeClassName="current" ><div>Kazım Yardımcı</div></Link></li>
                                <li className="menu-item"><Link to={'/varliktan-veriler'} className="menu-link" activeClassName="current"><div>Varlıktan Veriler</div></Link></li>
                                <li className="menu-item"><Link to={'/kitaplar'} className="menu-link" activeClassName="current" ><div>Kitaplar</div></Link></li>
                                <li className="menu-item"><Link to={'/tasavvuf-sohbetleri'} className="menu-link" activeClassName="current" ><div>Tasavvuf Sohbetleri</div></Link></li>
                                <li className="menu-item"><Link to={'/basindan-haberler'} className="menu-link" activeClassName="current" ><div>Basından Haberler</div></Link></li>
                            </ul>

                        </nav>

                        {/*<form className="top-search-form" action="search.html" method="get">*/}
                        {/*    <input type="text" name="q" className="form-control"*/}
                        {/*           placeholder="Ara &amp; Enter.." autoComplete="off"/>*/}
                        {/*</form>*/}

                    </div>
                </div>
            </div>
            <div className="header-wrap-clone"/>

        </header>
    )
}


export default Header

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    function handleResize() {
        // Set window width/height to state
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }
    useEffect(() => {
        // Handler to call on window resize

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize.width < 992;
}